// material-ui
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import { SalesStack } from '../landing/Sales';
import ExpertCard from './ExpertCard';
import ProfessionalCard from './ProfessionalCard';

// ===============================|| PRICING - PRICE 1 ||=============================== //

const PricingBody = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h1" color="primary" gutterBottom>
            Welche Lizenzart passt zu Ihrem Träger?
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="body1" gutterBottom sx={{ mb: 1 }}>
            Ab sofort unterstütz Sie Diingu bei Ihrem pädagogischen
            Qualitätsmanagement. Wählen Sie das passende Lizenzpaket und fangen
            Sie an Ihre Schulbegleitungen zeit- & ortsunabhängig sowie
            fallspezifisch über unsere E-Learning Plattform fortzubilden.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <ProfessionalCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <ExpertCard />
        </Grid>
        <Grid item xs={12} sx={{ mt: 8 }}>
          <Typography variant="h2" textAlign="center" color="primary">
            Jetzt Termin vereinbaren & kostenlos testen!
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ maxWidth: '77ch', mx: 'auto' }}>
          <Typography textAlign="center" gutterBottom>
            Lernen Sie unsere Plattform kennen - wir stellen Ihnen alle
            Funktionen vor. Danach können Sie Diingu einen ganzen Monat lang
            unverbindlich testen und sich selbst überzeugen.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 8, mx: isLargeScreen ? 24 : 0 }}>
          <SalesStack direction={isLargeScreen ? 'row' : 'column'} />
        </Grid>
      </Grid>
    </Container>
  );
};
export default PricingBody;
