import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  // ListItem,
  // ListItemIcon,
  Typography,
  Grid,
  useTheme
  // ListItemText
} from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
// import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ItemWithIcon from './IconWithText';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
// import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';

const imageDir = require.context('assets/images');

interface FeatureAccordionProps {
  icon: JSX.Element;
  title: string;
  description?: JSX.Element;
  details: string[];
  defaultExpanded?: boolean;
}

export const FeatureAccordion = ({
  icon,
  title,
  description,
  details,
  defaultExpanded = false
}: FeatureAccordionProps) => (
  <Accordion
    defaultExpanded={defaultExpanded}
    disableGutters
    sx={{
      border: `0px solid`,
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&::before': {
        display: 'none'
      }
    }}
  >
    <AccordionSummary
      expandIcon={<KeyboardArrowDownRoundedIcon color="primary" />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{ pl: 0, textAlign: 'start' }}
    >
      <ItemWithIcon icon={icon} text={<b>{title}</b>} />
    </AccordionSummary>
    <Typography textAlign="start">{description}</Typography>
    <AccordionDetails sx={{ textAlign: 'start', px: 0 }}>
      {details.map((item) => (
        <ItemWithIcon text={item} />
      ))}
    </AccordionDetails>
  </Accordion>
);

interface BasicFeaturesProps {
  expandAI?: boolean;
}

export const BasicFeatures = ({ expandAI = true }: BasicFeaturesProps) => {
  const theme = useTheme();

  return (
    <>
      <FeatureAccordion
        icon={<SchoolRoundedIcon color="primary" />}
        title="24+ Fortbildungskurse"
        description={
          <>
            Unser gesamtes Fortbildungsangebot, speziell für das Berufsbild
            Schulbegleitungen konzipiert & entwickelt von einem
            wissenschaftlichen Team aus den Bereichen inklusive Pädagogik,
            Psychologie und Psychiatrie.
          </>
        }
        details={[
          '3 Einarbeitungskurse',
          '13 Kurse zu seelischen, körperlichen und geistigen Behinderungen (nach SGB XIII und IX)',
          '8+ Zusatzkurse'
        ]}
      />
      <FeatureAccordion
        icon={<InsertChartOutlinedRoundedIcon color="primary" />}
        title="Verwaltungsfunktionen"
        description={
          <>
            Einfache Verwaltung des Fortbildungsmanagements, um Ihr
            Leitungspersonal zu entlasten.
          </>
        }
        details={[
          'Zertifikate für bestandene Kurse',
          'Statistiken zur Lernaktivität auf der Plattform',
          'Reporting über absolvierte Fortbildungen'
        ]}
      />
      <FeatureAccordion
        icon={
          <img
            src={imageDir('./message-ki.svg')}
            width={24}
            height={24}
            alt="Diingu"
            style={{
              color: theme.palette.primary.main,
              fill: theme.palette.primary.main
            }}
          />
        }
        title="KI Guide"
        description={
          <>
            Der erste KI-Chatbot für sozialpädagogische und psychologische
            Inhalte gibt Schulbegleitungen rund um die Uhr verlässliche
            Handlungsempfehlungen.
          </>
        }
        defaultExpanded={expandAI}
        details={[]}
      />
    </>
  );
};

const ProfessionalCard = () => {
  const theme = useTheme();

  return (
    <MainCard
      boxShadow
      sx={{
        border: '1px solid',
        borderColor: 'primary.main'
      }}
    >
      <Grid container textAlign="center" spacing={gridSpacing}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="primary"
            sx={{
              fontSize: '1.4rem',
              fontWeight: 500,
              position: 'relative',
              mb: 1.875,
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -15,
                left: 'calc(50% - 25px)',
                width: 50,
                height: 4,
                background: theme.palette.primary.main,
                borderRadius: '3px'
              }
            }}
          >
            <b>Standard</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            noWrap
            color="primary"
            sx={{
              fontSize: '4rem',
              fontWeight: 700,
              '& > span': {
                fontSize: '1.25rem',
                fontWeight: 500
              }
            }}
          >
            219€
          </Typography>
          <Typography gutterBottom>
            <span style={{ fontSize: '1rem' }}>
              <b>netto pro Person pro Jahr</b>
              <br />
              Ab 35 Lizenzen
            </span>
          </Typography>
          <Typography>
            Flexible und fallbezogene Fortbildung sowie <br />
            24/7-Unterstützung von unserem KI-Guide.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{
              m: 0,
              p: 0,
              '&> li': {
                px: 0,
                py: 0.625
                // '& svg': {
                //   fill: theme.palette.success.dark
                // }
              }
            }}
            component="ul"
          >
            <BasicFeatures />
          </List>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ProfessionalCard;
