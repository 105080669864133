import { Typography, Grid, useTheme } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import { BasicFeatures, FeatureAccordion } from './ProfessionalCard';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';

const ExpertCard = () => {
  const theme = useTheme();

  return (
    <MainCard
      boxShadow
      sx={{
        border: '2px solid',
        borderColor: 'secondary.dark'
      }}
    >
      <Grid container textAlign="center" spacing={gridSpacing}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="secondary"
            sx={{
              fontSize: '1.4rem',
              fontWeight: 500,
              position: 'relative',
              mb: 1.875,
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -15,
                left: 'calc(50% - 25px)',
                width: 50,
                height: 4,
                background: theme.palette.secondary.main,
                borderRadius: '3px'
              }
            }}
          >
            <b>Erweitert</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            noWrap
            color="secondary"
            sx={{
              fontSize: '4rem',
              fontWeight: 700,
              '& > span': {
                fontSize: '1.25rem',
                fontWeight: 500
              }
            }}
          >
            369€
          </Typography>
          <Typography gutterBottom>
            <span style={{ fontSize: '1rem' }}>
              <b>netto pro Person pro Jahr</b>
              <br />
              Ab 50 Lizenzen
            </span>
          </Typography>
          <Typography>
            Neue pädagogische Qualitätsstandards setzen und gemeinsam Inklusion
            gelingen lassen.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BasicFeatures expandAI={false} />
          <FeatureAccordion
            icon={<ContentPasteRoundedIcon color="primary" />}
            title="Kursentwicklung"
            description={
              <>
                Auf Wunsch entwickeln wir für Ihre Schulbegleitungen einen neuen
                Kurs pro Jahr.
              </>
            }
            details={[]}
            defaultExpanded
          />
          <FeatureAccordion
            icon={<SupportAgentRoundedIcon color="primary" />}
            title="Pädagogischer Support"
            description={
              <>
                Bei pädagogischen Fragen: Fallspezifische Unterstützung von
                Schulbegleitungen durch unser wissenschaftliches Team.
              </>
            }
            details={[]}
            defaultExpanded
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ExpertCard;
